import React from "react";
import "./styles/SmallLoader.scss"; // Make sure to create this CSS file for styling

const SmallLoader = () => {
  return (
    <div className="small-loader">
      <div className="spinner"></div>
    </div>
  );
};

export default SmallLoader;
