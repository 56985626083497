import icons from "./icons";

// export const DocumentTypes = ["CONTRACT", "PAYSLIP", "DECISIONS", "CV"];
export const DocumentTypes = [
  { type: "CONTRACT", title: "UGOVOR" },
  { type: "PAYSLIP", title: "PLATNI LISTIC" },
  { type: "DECISIONS", title: "RESENJE" },
  { type: "CV", title: "CV" },
];

export const CustomFieldTypes = [
  "Velicina",
  "Članovi Porodice",
  "Slava",
  "Vozač",
  "Alergije",
  "Disability",
];

export const CustomFieldIcons = {
  Velicina: icons.ellipse,
  "Članovi Porodice": icons.baby,
  Slava: icons.saint_day,
  Vozač: icons.car,
  Alergije: icons.important,
  Disability: icons.disability,
};
