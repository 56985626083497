import React, { useEffect } from "react";
import WorkHoursTrackerRow from "./WorkHoursTrackerRow";
import "../../../styles/attendancePage.scss";
import { useDispatch, useSelector } from "react-redux";
import { apiSlice } from "../../../../../store/apiSlice"; // Import apiSlice
import Loader from "../../../../../components/Loader/Loader";
import SmallLoader from "../../../../../components/SmallLoader/SmallLoader";
import ToastError from "../../../../../components/ToastError/ToastError";
import useInfiniteScroll from "../../../../../hooks/infiniteScroll";

const WorkHoursTracker = ({ status, date, activeButton, enterSearch }) => {
  const dispatch = useDispatch();
  const companyId = useSelector((state) => state.company?.data?.companyId);
  const size = 10; // Page size

  // Fetch function (called inside useInfiniteScroll)
  const fetchAttendances = async (page) => {
    if (!companyId) return { items: [], totalElements: 0 };

    try {
      const response = await dispatch(
        apiSlice.endpoints.getAttendancesForAttendancePage.initiate({
          variables: {
            companyId,
            date,
            search: enterSearch.trim(),
            page,
            size,
          },
        })
      ).unwrap();

      return {
        items: response?.data?.employeeLogsPerDay?.content || [],
        totalElements: response?.data?.employeeLogsPerDay?.totalElements || 0,
      };
    } catch (error) {
      console.error("API Error:", error);
      throw new Error("Failed to fetch data");
    }
  };

  // Use infinite scroll with correct API fetching
  const { data, loading, error, observerRef } = useInfiniteScroll(
    fetchAttendances,
    [companyId, date, enterSearch]
  );
  return (
    <div className="flex-direction-column align-center gap-12 align-stretch workHours-tracker">
      {data.map((item, index) => (
        <WorkHoursTrackerRow
          key={index}
          employed={item}
          status={status}
          date={date}
          activeButton={activeButton}
        />
      ))}

      {/* Error message */}
      {error && <ToastError message={error} />}

      {/* Intersection Observer for infinite scroll */}
      <div ref={observerRef} style={{ minHeight: "20px", width: "100%" }}>
        {loading && <SmallLoader />}
      </div>

      {/* Loader while fetching */}
    </div>
  );
};

export default WorkHoursTracker;
