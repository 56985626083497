import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { queries, mutations } from "../constants";
import UserService from "../services/userService";

export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_BASE_URL}/graphql`,
    prepareHeaders: (headers) => {
      // Set global headers
      headers.set("Content-Type", "application/json");
      const token = UserService.getToken();
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["Employees"],
  keepUnusedDataFor: 0,
  endpoints: (builder) => ({
    //
    // QUERIES
    //
    executeQuery: builder.query({
      query: ({ query, variables }) => ({
        url: "",
        method: "POST",
        body: { query, variables },
      }),
    }),
    getEmployees: builder.query({
      query: ({ variables }) => ({
        url: "",
        method: "POST",
        body: { query: queries.getEmployeesQuery, variables },
      }),
      providesTags: ["Employees"],
    }),
    getAttendances: builder.query({
      query: ({ variables }) => ({
        url: "",
        method: "POST",
        body: { query: queries.getAttendanceQuery, variables },
      }),
    }),
    getEmployeeHome: builder.query({
      query: ({ id }) => ({
        url: "",
        method: "POST",
        body: {
          query: queries.employeeHomeQuery,
          variables: { id },
        },
      }),
      providesTags: ["Logs"],
    }),
    getCompanyIdByUserId: builder.query({
      query: ({ id }) => ({
        url: "",
        method: "POST",
        body: {
          query: queries.getCompanyIdByUserIdQuery,
          variables: { id },
        },
      }),
    }),
    getCompanyInfo: builder.query({
      query: ({ id }) => ({
        url: "",
        method: "POST",
        body: {
          query: queries.getCompanyInfoQuery,
          variables: { id },
        },
      }),
    }),
    getProfilePersonalInfo: builder.query({
      query: ({ id }) => ({
        url: "",
        method: "POST",
        body: {
          query: queries.getProfilePersonalInfoQuery,
          variables: { id },
        },
      }),
    }),
    getPositionsAndSectors: builder.query({
      query: ({ id }) => ({
        url: "",
        method: "POST",
        body: {
          query: queries.getPositionsAndSectorsQuery,
          variables: { id },
        },
      }),
    }),
    getAttendaceForAdminHome: builder.query({
      query: ({ id }) => ({
        url: "",
        method: "POST",
        body: {
          query: queries.getAttendanceForAdminHomeQuery,
          variables: { id },
        },
      }),
    }),
    getListOfEmployees: builder.query({
      query: ({ variables }) => ({
        url: "",
        method: "POST",
        body: {
          query: queries.getListOfEmployeesQuery,
          variables,
        },
      }),
    }),
    getEmploymentInfo: builder.query({
      query: ({ id }) => ({
        url: "",
        method: "POST",
        body: {
          query: queries.getEmploymentInfoQuery,
          variables: { id },
        },
      }),
    }),
    getAttendancesForAttendancePage: builder.query({
      query: ({ variables }) => ({
        url: "",
        method: "POST",
        body: { query: queries.getAttendancePageQuery, variables },
      }),
    }),
    getEmployeesDocuments: builder.query({
      query: ({ employeeId, search, size, page, type }) => ({
        url: "",
        method: "POST",
        body: {
          query: queries.getEmployeeDocumentsQuery,
          variables: { employeeId, search, size, page, type },
        },
      }),
      providesTags: ["Documents"],
    }),
    getCustomFields: builder.query({
      query: ({ companyId }) => ({
        url: "",
        method: "POST",
        body: {
          query: queries.getCustomFieldsQuery,
          variables: { companyId },
        },
      }),
      providesTags: ["CustomFields"],
    }),
    getEmployeesExpirationContracts: builder.query({
      query: ({ companyId, search, size, page }) => ({
        url: "",
        method: "POST",
        body: {
          query: queries.getEmployeesExpirationContractsQuery,
          variables: { companyId, search, size, page },
        },
      }),
    }),
    getSingleEmployeeLogs: builder.query({
      query: ({ employeeId, from, to }) => ({
        url: "",
        method: "POST",
        body: {
          query: queries.getSingleEmployeeLogsQuery,
          variables: { employeeId, from, to },
        },
      }),
    }),
    searchEmployees: builder.query({
      query: ({ companyId, search }) => ({
        url: "",
        method: "POST",
        body: {
          query: queries.searchEmployeesQuery,
          variables: { companyId, search },
        },
      }),
    }),
    getCumulativeLogs: builder.query({
      query: ({ variables }) => ({
        url: "",
        method: "POST",
        body: {
          query: queries.cumulativeLogsQuery,
          variables,
        },
      }),
    }),

    //
    // MUTATIONS
    //

    createEmployee: builder.mutation({
      query: ({ input }) => ({
        url: "",
        method: "POST",
        body: {
          query: mutations.createEmployeeMutation,
          variables: { input },
        },
      }),
      invalidatesTags: ["Employees"],
    }),
    createCompany: builder.mutation({
      query: ({ input }) => ({
        url: "",
        method: "POST",
        body: {
          query: mutations.createCompanyMutation,
          variables: { input },
        },
      }),
    }),

    createWebLog: builder.mutation({
      query: ({ input }) => ({
        url: "",
        method: "POST",
        body: {
          query: mutations.createWebLogMutation,
          variables: { input },
        },
      }),
      invalidatesTags: ["Logs"],
    }),

    createDocument: builder.mutation({
      query: ({ input }) => ({
        url: "",
        method: "POST",
        body: {
          query: mutations.createDocumentMutation,
          variables: { input },
        },
      }),
      invalidatesTags: ["Documents"],
    }),
    confirmDocument: builder.mutation({
      query: ({ id }) => ({
        url: "",
        method: "POST",
        body: {
          query: mutations.confirmDocumentMutation,
          variables: { id },
        },
      }),
    }),
    createCustomField: builder.mutation({
      query: ({ companyId, description, name }) => ({
        url: "",
        method: "POST",
        body: {
          query: mutations.createCustomFieldMutation,
          variables: { companyId, description, name },
        },
      }),
      invalidatesTags: ["CustomFields"],
    }),
    createLog: builder.mutation({
      query: ({ input }) => ({
        url: "",
        method: "POST",
        body: {
          query: mutations.createLogMutation,
          variables: { input },
        },
      }),
    }),
    updateLog: builder.mutation({
      query: ({ id, input }) => ({
        url: "",
        method: "POST",
        body: {
          query: mutations.updateLogMutation,
          variables: { id, input },
        },
      }),
    }),
    markDocumentDownloadedMutation: builder.mutation({
      query: ({ id }) => ({
        url: "",
        method: "POST",
        body: {
          query: mutations.markDocumentDownloadedMutation,
          variables: { id },
        },
      }),
      invalidatesTags: ["Documents"],
    }),
  }),
});

export const {
  //QUERIES
  useExecuteQueryQuery: useExecuteQuery,
  useGetEmployeesQuery,
  useGetAttendancesQuery,
  useGetEmployeeHomeQuery,
  useGetCompanyIdByUserIdQuery,
  useGetCompanyInfoQuery,
  useGetProfilePersonalInfoQuery,
  useGetPositionsAndSectorsQuery,
  useGetAttendaceForAdminHomeQuery,
  useGetListOfEmployeesQuery,
  useGetEmploymentInfoQuery,
  useGetAttendancesForAttendancePageQuery,
  useGetEmployeesDocumentsQuery,
  useGetCustomFieldsQuery,
  useGetEmployeesExpirationContractsQuery,
  useGetSingleEmployeeLogsQuery,
  useSearchEmployeesQuery,
  useGetCumulativeLogsQuery,

  // MUTATIONS
  useCreateEmployeeMutation,
  useCreateCompanyMutation,
  useCreateWebLogMutation,
  useCreateDocumentMutation,
  useConfirmDocumentMutation,
  useCreateCustomFieldMutation,
  useCreateLogMutation,
  useUpdateLogMutation,
  useMarkDocumentDownloadedMutationMutation,
} = apiSlice;
