import React, { useState } from "react";
import "./styles/Calendar.scss";
import "../../styles/global.scss";
import CalendarNavigation from "./components/CalendarNavigation";
import DateSelector from "./components/DateSelector";
import { useEffect } from "react";

const Calendar = ({ onChange, doubleDate, firstDate, secondDate, over }) => {
  const [evenClick, setEvenClick] = useState(false);
  const currentDate = new Date();
  const [date, setDate] = useState({
    day: String(currentDate.getDate()),
    month: String(currentDate.getMonth() + 1),
    year: String(currentDate.getFullYear()),
  });

  const [selectedDay, setSelectedDay] = useState(
    !doubleDate
      ? `${date.year}-${String(date.month).padStart(2, "0")}-${String(
          date.day
        ).padStart(2, "0")}`
      : {
          firstDate: firstDate
            ? firstDate
            : `${date.year}-${String(date.month).padStart(2, "0")}-${String(
                date.day
              ).padStart(2, "0")}`,
          secondDate,
        }
  );

  const handleChangeDate = (datePart, value) => {
    if (datePart === "month") {
      if (value === 12 && date.month === 1) {
        setDate((prevDate) => ({
          ...prevDate,
          year: String(Number(prevDate.year) - 1),
        }));
      } else if (value === 1 && date.month === 12) {
        setDate((prevDate) => ({
          ...prevDate,
          year: String(Number(prevDate.year) + 1),
        }));
      }
    }
    setDate((prevDate) => ({
      ...prevDate,
      [datePart]: value,
    }));
  };

  useEffect(() => {
    if (selectedDay) {
      if (doubleDate) {
        onChange && onChange(selectedDay.firstDate, selectedDay.secondDate);
      } else {
        onChange && onChange(selectedDay);
      }
    }
  }, [selectedDay]);

  const handleSetSelectedDay = (day, month, year) => {
    if (doubleDate) {
      const [stringYear, stringMonth, stringDay] = !evenClick
        ? selectedDay.firstDate.split("-").map(Number)
        : selectedDay.secondDate.split("-").map(Number);
      const dateFromString = new Date(stringYear, stringMonth - 1, stringDay);
      const dateFromVariables = new Date(year, month - 1, day);

      // if (dateFromString < dateFromVariables) {
      setEvenClick(!evenClick);
      // }

      const firstDate =
        dateFromString < dateFromVariables
          ? `${stringYear}-${String(stringMonth).padStart(2, "0")}-${String(
              stringDay
            ).padStart(2, "0")}`
          : `${year}-${String(month).padStart(2, "0")}-${String(day).padStart(
              2,
              "0"
            )}`;

      const secondDate =
        dateFromString > dateFromVariables
          ? `${stringYear}-${String(stringMonth).padStart(2, "0")}-${String(
              stringDay
            ).padStart(2, "0")}`
          : `${year}-${String(month).padStart(2, "0")}-${String(day).padStart(
              2,
              "0"
            )}`;

      setSelectedDay({
        firstDate: firstDate,
        secondDate: secondDate,
      });
    } else {
      setSelectedDay(
        `${year}-${String(month).padStart(2, "0")}-${String(day).padStart(
          2,
          "0"
        )}`
      );
    }
  };

  return (
    <div
      className={`flex-direction-column gap-24 width-100 calendar ${
        over ? "over" : ""
      }`}
    >
      <CalendarNavigation
        month={date.month}
        year={date.year}
        changeDate={(date, value) => handleChangeDate(date, value)}
      />
      <DateSelector
        date={date}
        selectedDay={selectedDay}
        doubleDate={doubleDate}
        setSelectedDay={(day, month, year) => {
          handleSetSelectedDay(day, month, year);
        }}
      />
    </div>
  );
};

export default Calendar;
