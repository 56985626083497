import React from "react";
import "./styles/modal.scss";
import "../../styles/global.scss";
import { useTheme } from "@mui/material";
import { icons } from "../../constants";
import ButtonApp from "../ButtonsApp/ButtonRegular";
import ModalForm from "./components/ModalForm";

const ModalApp = ({
  title,
  documentType,
  handleSaveClick,
  handleCloseClick,
  profileFile,
  optionsArray,
  onOptionsChange,
  profileInfo,
  setFile,
  description,
  setDescription,
  loading,
}) => {
  const theme = useTheme();
  const defaultBackground = theme.palette.background.default;
  return (
    <div
      className="flex-direction-column gap-20 align-start shadows-0-9-20 modal"
      style={{ backgroundColor: defaultBackground }}
    >
      <div className="flex-direction-row justify-space-between align start width-100">
        <p className="Grotesk-500 font-16">{title}</p>
        <img
          src={icons.modalX}
          alt="x"
          width={24}
          height={24}
          className="pointer"
          onClick={handleCloseClick}
        />
      </div>
      <ModalForm
        documentType={documentType}
        profileFile={profileFile}
        profileInfo={profileInfo}
        optionsArray={optionsArray}
        onOptionsChange={onOptionsChange}
        description={description}
        setDescription={setDescription}
        setFile={setFile}
      />
      <div className="flex-direction-row gap-10 align-center width-100 modal-buttons">
        <ButtonApp
          title="Otkazi"
          width="100%"
          onButtonClick={handleCloseClick}
        />
        <ButtonApp
          purple
          title="Sacuvaj"
          width="100%"
          onButtonClick={handleSaveClick}
        />
      </div>
    </div>
  );
};

export default ModalApp;
