import React, { useState } from "react";
import ButtonApp from "../../../components/ButtonsApp/ButtonRegular";
import ButtonFolder from "../../../components/ButtonsApp/ButtonSpecial";
import { documentsButtons } from "../constants";
import { icons } from "../../../constants";
import { useTheme } from "@mui/material";
import ModalApp from "../../../components/ModalApp/ModalApp";
import useUploadDocument from "../../../hooks/useUploadDocument";
import {
  useGetEmployeesDocumentsQuery,
  useMarkDocumentDownloadedMutationMutation,
} from "../../../store/apiSlice";
import { DocumentTypes } from "../../../constants/types";
import { useSelector } from "react-redux";
import { idSelector } from "../../../store/companySlice";
import { handleDownload } from "../../../hooks/downloadDocument";
import ToastError from "../../../components/ToastError/ToastError";
import DocumentsTable from "./DocumentsTable";

const Documents = ({ id }) => {
  const [openModal, setOpenModal] = useState(false);
  const companyId = useSelector(idSelector);

  const [activeFolder, setActiveFolder] = useState(null);

  const [searchParams, setSearchParams] = useState({
    search: "",
    size: 20,
    page: 0,
    type: undefined,
  });
  const {
    data,
    isLoading,
    isFetching,
    isError: documentsError,
  } = useGetEmployeesDocumentsQuery({
    employeeId: id,
    ...searchParams,
  });

  const documents = data?.data?.employeeDocuments?.content || [];

  const {
    uploadDocument,
    loading: isUploading,
    error: uploadError,
  } = useUploadDocument();
  const [markDocument] = useMarkDocumentDownloadedMutationMutation();

  const [file, setFile] = useState(null);
  const [documentType, setDocumentType] = useState(null);

  const theme = useTheme();
  const neutralMedium = theme.palette.neutral.medium;

  const handleAddFile = () => {
    setOpenModal(true);
  };

  const handleSubmit = async () => {
    setOpenModal(false);
    let type = DocumentTypes.find((item) => item.title === documentType);
    await uploadDocument(file, type.type, companyId, id);
    handleClearForm();
  };

  const handleClearForm = () => {
    setFile(null);
    setDocumentType("");
  };

  const handleClickDocumentButton = (index, type) => {
    if (activeFolder === index) {
      setActiveFolder(null);
      setSearchParams({ ...searchParams, type: undefined });
    } else {
      setActiveFolder(index);
      setSearchParams({ ...searchParams, type });
    }
  };

  const checkActive = (index) => {
    return activeFolder === index;
  };

  const handleDocumentClick = async (item) => {
    handleDownload(item?.url, item?.name);
    await markDocument({ id: item?.id });
  };

  const handleClose = () => {
    setOpenModal(false);
    handleClearForm();
  };

  const types = DocumentTypes.map((type) => type.title);

  if (documentsError || uploadError) {
    return (
      <ToastError message="Doslo je do greske vidite konzolu za vise informacija" />
    );
  }

  return (
    <div className="flex-direction-column gap-32 align-start width-100 documents animated">
      <div className="flex-direction-column align-start gap-24 width-100">
        <div className="flex-direction-row justify-space-between align-center align-stretch width-100 superiors-btn">
          <p className="Grotesk-500 font-16" style={{ color: neutralMedium }}>
            Folderi
          </p>
          <ButtonApp title="Dodaj" purple onButtonClick={handleAddFile} />
        </div>
        <div className="flex-direction-row gap-24 justify-center align-center width-100 documents-buttons">
          {documentsButtons.length > 0 && (
            <>
              <div className="flex-direction-row gap-24 justify-space-between align-center width-100 documents-two-buttons">
                {documentsButtons.slice(0, 2).map((button) => (
                  <ButtonFolder
                    key={button.id}
                    folder
                    backgroundColor={button.color}
                    title={button.title}
                    type={button.type}
                    active={checkActive(button.id)}
                    onClickButton={() =>
                      handleClickDocumentButton(button.id, button.type)
                    }
                    className="documents-btn"
                  />
                ))}
              </div>
              <div className="flex-direction-row gap-24 justify-space-between align-center width-100 documents-two-buttons">
                {documentsButtons.slice(2, 4).map((button) => (
                  <ButtonFolder
                    key={button.id}
                    folder
                    backgroundColor={button.color}
                    title={button.title}
                    type={button.type}
                    active={checkActive(button.id)}
                    onClickButton={() =>
                      handleClickDocumentButton(button.id, button.type)
                    }
                    className="documents-btn"
                  />
                ))}
              </div>
            </>
          )}
        </div>
      </div>
      <div className="flex-direction-column align-end gap-32 width-100 documents-container">
        <DocumentsTable
          title="Dokumenta"
          data={documents}
          onRowClick={handleDocumentClick}
          isLoading={isLoading || isFetching}
          missingDataText="Nema dokumenata!"
        />
        <ButtonApp
          title="Vidi više"
          icon={icons.arrow_right}
          className="team-see-more"
          onButtonClick={() => {}}
        />
      </div>
      {openModal && (
        <div className="flex-direction-row align-center justify-center add-notification">
          <ModalApp
            title="Dodaj dokument"
            handleSaveClick={handleSubmit}
            handleCloseClick={handleClose}
            profileFile
            documentType={documentType}
            optionsArray={types}
            onOptionsChange={setDocumentType}
            setFile={setFile}
            loading={isUploading}
          />
        </div>
      )}
    </div>
  );
};

export default Documents;
